import React, { Fragment } from "react";

const InteractiveVideo = () => {
  // const style = {
  //   height: "400px",
  //   width: "100%",
  // };
  // const frameStyle = {
  //   width: "100%",
  //   height: "100%",
  //   objectFit: "cover",
  // };
  return (
    <Fragment>
      {/* <iframe
        id='myiFrame'
        title='Interactive Video'
        style={style}
        src='https://www.safetyleadersfoundation.com/cluevo/lms/my-learning-tree/interactive-animation/'
        frameBorder='0'
      ></iframe> */}
      {/* <div style={style}>
        <iframe
          style={frameStyle}
          src='https://player.vimeo.com/video/278526536?autoplay=0&amp;loop=1&amp;muted=1&amp;title=0&amp;byline=0&amp;portrait=0'
          frameBorder='0'
          allowFullScreen='allowfullscreen'
          allow='autoplay'
          name='fitvid0'
          title='Animations'
        ></iframe>
      </div> */}
    </Fragment>
  );
};

export default InteractiveVideo;
